import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import { IoMdCall } from "react-icons/io";

const CtaBanner = ({
  headline,
  description,
  btnTxt,
  btnLink,
  gradient,
  style,
  phoneText,
  phone,
}) => {
  const ctaLink = btnLink || "/contact-us";

  return (
    <section
      className={` position-relative ${gradient ? "" : "bg-banner"} py-5`}
      style={style}
    >
      <div
        className={`h-100 w-100 top-0 position-absolute ${
          gradient ? "" : "d-none"
        } `}
        style={{
          background:
            "linear-gradient(269.28deg, #fbba00 3.32%, #FFD337 101.94%)",
          zIndex: -1,
        }}
      ></div>
      <Container>
        <Row className="align-items-lg-center text-center justify-content-center">
          <Col lg={7} className="mb-4 mb-md-0 ">
            <h2 className="mb-3">{headline}</h2>
            {description && <p>{description}</p>}
            <div className="d-flex align-items-center mt-4 justify-content-center flex-column flex-lg-row	">
              <Button
                as={Link}
                variant="secondary"
                size="lg"
                className="btn-black  w-100 w-md-auto cta-btn me-2"
                to={ctaLink}
                id="cta-banner-button"
              >
                {btnTxt}
              </Button>
              {phone && (
                <Button
                  as={Link}
                  variant="transparent"
                  size="lg"
                  className="w-100 w-md-auto cta-btn px-4 d-flex align-items-center mt-4 mt-lg-0 justify-content-center"
                  href={`tel:+44${phone}`}
                  id="cta-banner-button"
                >
                  <IoMdCall className="me-2" /> {phoneText}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CtaBanner;
